// ProtectedRoute.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { checkUserSession, selectAuthenticated, logoutUser } from './features/user/userSlice';

function ProtectedRoute({ element }) {
    const isAuthenticated = useSelector(selectAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAuthenticated) {
            const verifySession = async () => {
                const sessionValid = await dispatch(checkUserSession());
                if (!sessionValid) {
                    dispatch(logoutUser());
                    localStorage.removeItem('userData');
                }
            };
            verifySession();
        }
    }, [dispatch, isAuthenticated]);

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return element;
}

export default ProtectedRoute;
